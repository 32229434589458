<template>
  <div class="vld-parent">
    <loading :active.sync="isLoading" :can-cancel="false" />
    <CRow>
      <CCol sm="6" v-for="(product, i) in products" :key="i">
        <CCard>
          <CCardHeader>
            <CIcon name="cil-gamepad" /><strong> {{ product.product_code }} </strong>
          </CCardHeader>
          <CCardBody>
            <dl class="row">
              <dt class="col-sm-3">Product</dt>
              <dd class="col-sm-9">{{ product.product_code }}</dd>

              <dt class="col-sm-3">Flagship</dt>
              <dd class="col-sm-9">{{ product.flagship }}</dd>

              <dt class="col-sm-3">Price</dt>
              <dd class="col-sm-9">${{ product.cost }}</dd>

              <dt class="col-sm-3">Promo Price</dt>
              <dd class="col-sm-9">${{ product.promo }}</dd>

              <dt class="col-sm-3">Primary Tagline</dt>
              <dd class="col-sm-9"><CInput type="text" horizontal v-model="product.description"  /></dd>

              <dt class="col-sm-3">Tags</dt>
              <dd class="col-sm-9">
                <CListGroup>
                  <CListGroupItem v-for="(tagline, t) in product.tagLines" :key="t" href="#" @click="updateTag(product, tagline)">
                    <CRow style="justify-content: space-between; margin: 0px 5px 0px 5px;">
                      {{ tagline }}
                      <CButton style="height: 24px; width: 24px; padding: 0px 0px 2px 0px;"  color="danger" @click="removeTag(product, tagline)" :disabled="isUpdating">x</CButton>
                    </CRow>
                  </CListGroupItem>
                </CListGroup>
              </dd>
            </dl>
            <CRow style="flex-direction: row-reverse; margin-right:5px;">
              <CButton color="info" @click="addTag(product)" :disabled="isUpdating">
                  <span v-if="isUpdating" class="spinner-border spinner-border-sm" role="status" style="margin-right: 15px;" />
                  +
              </CButton>
              <CInput type="text" style="margin: 0px; width: 360px;" placeholder="Add Tagline" horizontal v-model="product.newtagline" :key="tagKey"  />
            </CRow>
          </CCardBody>
          <CCardFooter>
            <CRow style="justify-content: space-between; margin-left: 5px; margin-right:5px;">
              <CButton color="primary" class="px-4" @click="updateProduct(product)" :disabled="isUpdating">
                  <span v-if="isUpdating" class="spinner-border spinner-border-sm" role="status" style="margin-right: 15px;" />
                  Update
              </CButton>
            </CRow>
          </CCardFooter>
        </CCard>
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="6">
        <CCard>
          <CCardHeader>
            <CIcon name="cil-money" /><strong> Promotion Pricing</strong>
          </CCardHeader>
          <CCardBody>
            <dl class="row">
              <dt class="col-sm-3">Price</dt>
              <dd class="col-sm-9"><CInput type="money" horizontal v-model="promoPrice"  /></dd>
            </dl>
            <CRow style="justify-content: center;">
              Set to $0 to remove promotional pricing
            </CRow>
          </CCardBody>
          <CCardFooter>
            <CRow style="justify-content: space-between; margin-left: 5px; margin-right:5px;">
              <CButton color="primary" class="px-4" @click="promoModal = true" :disabled="isUpdating">
                  <span v-if="isUpdating" class="spinner-border spinner-border-sm" role="status" style="margin-right: 15px;" />
                  Set Promotion
              </CButton>
            </CRow>
          </CCardFooter>
        </CCard>
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="6">
        <CCard>
          <CCardHeader>
            <CIcon name="cil-lock-locked" /><strong> Key Finder</strong>
          </CCardHeader>
          <CCardBody>
            <dl class="row">
              <dt class="col-sm-3">Key</dt>
              <dd class="col-sm-9"><CInput horizontal v-model="keysearch"  /></dd>
            </dl>
            <CDataTable
                v-if="showKeyResults"
                :items="keyResults"
                :fields="keyResultFields">

                <template #fpurchaser="{ item }">
                  <td class="py-2">
                      <CButton v-if="item.purchaser > 0"
                              color="primary"
                              variant="outline"
                              square
                              size="sm"
                              @click="viewSub(item.purchaser)">
                          View Purchaser
                      </CButton>
                      <span v-else>N/A</span>
                  </td>
                </template>

                <template #fowner="{ item }">
                  <td class="py-2">
                      <CButton v-if="item.owner > 0"
                              color="primary"
                              variant="outline"
                              square
                              size="sm"
                              @click="viewSub(item.owner)">
                          View Owner
                      </CButton>
                      <span v-else>N/A</span>
                  </td>
                </template>

                <template #fconsumed_on="{ item }">
                    <td class="py-2">{{ item.consumed_on | formatDate }}</td>
                </template> 
            </CDataTable>
          </CCardBody>
          <CCardFooter>
            <CRow style="justify-content: space-between; margin-left: 5px; margin-right:5px;">
              <CButton color="primary" class="px-4" :disabled="isUpdating" @click="searchKey">
                  <span v-if="isUpdating" class="spinner-border spinner-border-sm" role="status" style="margin-right: 15px;" />
                  Search Key
              </CButton>
            </CRow>
          </CCardFooter>
        </CCard>
      </CCol>
    </CRow>
    <CModal title="Set Promotion Price" color="info"  :show.sync="promoModal" @update:show="closePromoModal">
      Are you sure you wish to change the flagship price?
    </CModal>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";

export default {
  name: "Products",
  components: {
    Loading,
  },
  data() {
    return {
      isLoading: false,
      isUpdating: false,
      promoModal: false,
      promoPrice: 0,
      tagKey: 0,
      products: [],
      keysearch: null,
      keyResults: [],
      showKeyResults: false,
      keyResultFields: [
          { key: "keyType", label: "Type", filter: false, sorter: false },
          { key: "product_code", label: "Product", filter: false, sorter: false },
          { key: "fpurchaser", label: "Purchaser", filter: false, sorter: false },
          { key: "fowner", label: "Owner", filter: false, sorter: false },
          { key: "fconsumed_on", label: "Consumed Date", filter: false, sorter: false }
      ],
    };
  },
  methods: {
    getProducts() {
      this.isLoading = true;

      this.$http.get(this.$config.gmapi + "/Product", {
          headers: this.$config.getHeaders(),
        }).then((response) => {
          return response.json();
        }).then((jsonData) => {
          this.products = jsonData;
        }).catch(function (error) {
          this.isLoading = false;
          this.$root.$emit('onErr', error);
        }).finally(function () {
          this.isLoading = false;
        });
    },
    addTag(product) {
      if (product.updateTag && product.updateTag != '') {
        for (var i = 0; i < product.tagLines.length; i++) {
          if (product.tagLines[i] == product.updateTag) {
            product.tagLines[i] = product.newtagline;
          }
        }
        product.newtagline = '';
        product.updateTag = '';
      } else {
        product.tagLines.unshift(product.newtagline);
        product.newtagline = '';
      }
      this.tagKey++;
    },
    updateTag(product, updateTag) {
      product.updateTag = updateTag;
      product.newtagline = updateTag;

      this.tagKey++;
    },
    removeTag(product, removeTag) {
      const index = product.tagLines.indexOf(removeTag);
      if (index > -1) {
        product.tagLines.splice(index, 1);
      }
      product.newtagline = '';
      product.updateTag = '';

      this.tagKey++;
    },
    updateProduct(product) {
      this.isUpdating = true;
      this.$http.put(this.$config.gmapi + '/Product', product, {headers: this.$config.getHeaders() }).then(response => {
        this.isUpdating = false;
      }).catch(function (error) {
        this.$root.$emit('onErr', error);
        this.isUpdating = false;
      }).finally(function () {
        this.isUpdating = false;
        this.getProducts();
      });
    },
    closePromoModal(status, evt) { 
      if (evt.target.innerText.trim() === "OK") {
        this.isUpdating = true;
        this.$http.put(this.$config.gmapi + '/Product/promo?promo=' + this.promoPrice, null, {headers: this.$config.getHeaders() }).then(response => {
            this.isUpdating = false;
            this.getProducts();
        }).catch(function (error) {
          console.log(error);
          this.$root.$emit('onErr', error);
            this.isUpdating = false;
        }); 
      } 
    },
    searchKey() {
      this.isLoading = true;

      this.$http.get(this.$config.gmapi + "/Product/Keys?key=" + this.keysearch, {
          headers: this.$config.getHeaders(),
        }).then((response) => {
          return response.json();
        }).then((jsonData) => {
          this.keyResults = jsonData;
        }).catch(function (error) {
          this.isLoading = false;
          this.$root.$emit('onErr', error);
        }).finally(function () {
          this.isLoading = false;
          this.showKeyResults = true;
        });
    },
    viewSub(id) {
      this.$router.push({ name: "Account", params: { id: id } });
    }
  },
  created() {
    this.getProducts();
  },
};
</script>
